import styles from './LoadingButton.module.css'
import CircleSpinner from 'components/Spinner/CircleSpinner'

type Props = {
  label?: string,
}

const LoadingButton = ({
  label = 'Loading...',
}: Props) =>
  <div className={styles.loadingButton}>
    <CircleSpinner />
    <div className={styles.label}>{ label }</div>
  </div>

export default LoadingButton
