import { Blog } from '@prisma/client'
// import { updateData } from 'data/api'
import { BlogPartial, BlogWithPageViews } from 'data/blogs/types'
import { BlogSettings } from 'data/pages/types'
import { useRouter } from 'next/router'
import useSWR, { KeyedMutator } from 'swr'
import { post } from 'utilities/api'

let mutateFn: KeyedMutator<{ blogs: Blog[] }>

export const useBlogs = () => {
  // console.log('useBlogs')
  const { data, error, mutate } = useSWR<{ blogs: Blog[] }>('/user/blogs')
  mutateFn = mutate
  const blogs = data?.blogs

  return {
    blogs,
    refreshBlogs: mutate,
    isBlogsLoading: !error && !blogs,
    isBlogsError: error,
  }
}

type UseBlogWithSlugOptions = {
  pollForRefresh?: boolean,
}

export const useBlogWithSlug = (blogSlug?: string, options?: UseBlogWithSlugOptions) => {
  // console.log('useHost', hostId)
  const swrOptions = {
    refreshInterval: options?.pollForRefresh ? 30000 : 0,
  }
  const { data: blog, error } = useSWR<BlogWithPageViews>(blogSlug ? `/blogs/${blogSlug}` : null, swrOptions)

  return {
    blog,
    isBlogLoading: !error && !blog,
    isBlogError: error,
  }
}

export const useBlogSettingsWithSlug = (blogSlug?: string) => {
  // console.log('useHost', hostId)
  const { data: blogSettings, error } = useSWR<BlogSettings>(blogSlug ? `/blogs/${blogSlug}/settings` : null)

  return {
    blogSettings,
    isBlogSettingsLoading: !error && !blogSettings,
    isBlogSettingsError: error,
  }
}

export const useIsAdminUserForBlog = (blogSlug?: string) => {
  // console.log('Use IsAdminUser', blogSlug)
  const { data: isAdminUser, error } = useSWR<boolean>(blogSlug ? `/blogs/${blogSlug}/isAdmin` : null)
  return {
    isAdminUser,
    idAdminUserLoading: !error && !isAdminUser,
    idAdminUserError: error,
  }
}

export const updateBlogs = async (payload: BlogPartial) => {
  // console.log('update blogs', { mutateFn })
  if (mutateFn) {
    await post('/user/blogs', payload)
    mutateFn()
  } else {
    throw new Error('Blog Update Error')
  }
}

export const useCurrentBlogId = () => {
  const router = useRouter()
  const { hostId: blogIdFromUrl } = router.query
  // const [currentBlogId, setCurrentBlogId] = useState(blogIdFromUrl)
  // useEffect(() => {
  //   console.log({ blogIdFromUrl })
  //   setCurrentBlogId(blogIdFromUrl)
  // }, [blogIdFromUrl])
  if (blogIdFromUrl) {
    setCurrentBlogIdFromLocalStorage(blogIdFromUrl as string)
  }
  const blogId = blogIdFromUrl || getCurrentBlogIdFromLocalStorage()
  // console.log({ blogId })
  return blogId as string | undefined
}

const localStorageKeyForCurrentBlogId = 'currentBlogId'

const getCurrentBlogIdFromLocalStorage = () => {
  if (typeof window == 'undefined') {
    return undefined
  }
  const blogId = window.localStorage.getItem(localStorageKeyForCurrentBlogId)
  return blogId
}

const setCurrentBlogIdFromLocalStorage = (blogId: string) => {
  if (typeof window == 'undefined') {
    return undefined
  }
  window.localStorage.setItem(localStorageKeyForCurrentBlogId, blogId)
}
