import classNames from 'classnames'
import { isFunction } from 'lodash'
import styles from './Button.module.css'
import LoadingButton from 'components/LoadingButton'

export type ButtonVariant = 'tiny' | 'small' | 'medium' | 'large' | 'smallText'

export type ButtonProps = {
  children: React.ReactNode,
  className?: string,
  onClick?: (e: Event | undefined) => void | Event,
  variant?: ButtonVariant,
  type?: 'button' | 'submit' | 'reset',
  isDisabled?: boolean,
  isSecondary?: boolean,
  isWorking?: boolean,
  isWorkingLabel?: string,
  color?: 'indigo' | 'red' | 'blue' | 'cerise' | 'rose',
  isNoWrap?: boolean,
  icon?: React.ReactNode,
}

const colorOptions = [
  'bg-rose-600',
  'bg-rose-500',
  'ring-rose-500',
]

// const classNamesForVariant = {
//   primary:


// }

const Button = ({
  children,
  className,
  color = 'indigo',
  onClick,
  variant = 'medium',
  type = 'button',
  isDisabled = false,
  isSecondary = false,
  isNoWrap = true,
  isWorking = false,
  isWorkingLabel,
  icon,
  ...buttonProps
}: ButtonProps) => {
  const handleClick = (e:any) =>
    isFunction(onClick) && onClick(e)
  return <button
    {...buttonProps}
    onClick={isFunction(onClick) ? handleClick : undefined}
    className={classNames(
      styles.button,
      styles[variant],
      className, {
        [styles.secondary]: isSecondary,
        [styles.isDisabled]: isDisabled,
        [styles.buttonNoWrap]: isNoWrap,
      },
      color ? `text-white bg-${color}-600 hover:bg-${color}-500 focus:ring-${color}-500` : '',
    )}
    disabled={isDisabled}
    type={type}
  >
    <ButtonContent icon={icon} children={children} />
    { isWorking && <LoadingButton label={isWorkingLabel} /> }
  </button>
}

type ButtonContentProps = {
  icon?: React.ReactNode,
  children: React.ReactNode,
}

const ButtonContent = ({ icon, children }: ButtonContentProps) =>
  <>
    { icon && <div className={styles.icon}>
      { icon }
    </div> }
    { children }
  </>

export default Button
