import { useCurrentBlogId } from 'data/blogs/client'
import { Page } from 'data/pages/types'
import { isEmpty } from 'lodash'
// import { useRouter } from 'next/router'
// import { useEffect, useState } from 'react'
import useSWR, { KeyedMutator } from 'swr'
// import { getBlotterIdFromHostAndUrl } from 'utilities/url'

let mutatePagesFn: KeyedMutator<Page[]>

export const usePagesForDomain = () => {
  // const router = useRouter()

  // const pageUrl = `${hostId}:::${router.asPath}`
  // const [hostUrl, setHostUrl] = useState<string>()
  // const router = useRouter()
  // const { hostId } = router.query
  const hostId = useCurrentBlogId()
  // useEffect(() => {

  //   const thisHostUrl = encodeURIComponent()
  //   console.log('usePagesForDomain', thisHostUrl)
  //   setHostUrl(thisHostUrl)
  // }, [])

  const { data: pages, error, mutate: updatePages } = useSWR<Page[]>(hostId ? `/hosts/${hostId}/pages` : null)
  // console.log('Use Pages For Domain', { pages })
  // console.log('useUrlsWithPageViews blId', hostId)
  mutatePagesFn = updatePages
  return {
    pages,
    isPagesLoading: !error && !pages,
    isPagesError: error,
  }
}

let mutatePageFnById: { [key: string]: KeyedMutator<Page> }
let mutatePageFnByUrl: { [key: string]: KeyedMutator<Page> }

export const usePage = (pageId: string) => {
  const { data: page, error, mutate: updatePage } = useSWR<Page>(pageId ? `/pages/${pageId}` : null)
  // console.log('USE PAEG', page)
  mutatePageFnById = {
    ...(mutatePageFnById || {}),
    [pageId]: updatePage,
  }
  return {
    page,
    isPageLoading: !error && !page,
    isPageError: error,
    updatePage,
  }
}

export const usePageWithUrl = (encodedUrl: string) => {
  const { data: page, error, mutate: updatePageWithUrl } = useSWR<Page>(encodedUrl ? `/pages/byurl/${encodedUrl}` : null)
  // console.log('USE PAGE WITH URL', encodedUrl, page)
  mutatePageFnByUrl = {
    ...(mutatePageFnByUrl || {}),
    [encodedUrl]: updatePageWithUrl,
  }
  return {
    page,
    isPageLoading: !error && !page,
    isPageError: error,
    updatePageWithUrl,
  }
}

export const refreshAllPages = () => {
  if (mutatePagesFn || !isEmpty(mutatePageFnById)) {
    mutatePagesFn?.()
    Object.values(mutatePageFnById)?.map((mutateFn: KeyedMutator<Page>) => {
      mutateFn()
    })
  } else {
    throw new Error('Pages Update Error')
  }
}

export const refreshPageById = (pageId: string) => {
  console.log('Refresh Page By Id', pageId)
  if (mutatePageFnById?.[pageId]) {
    mutatePageFnById[pageId]()
  } else {
    throw new Error('Page Update Error')
  }
}

// export const useConversationById = (conversationId?: string) => {
//   console.log('useConversationById', conversationId)
//   const { data, error } = useSWR<Conversation>(conversationId ? `/conversations/?id=${conversationId}` : null)
//   console.log({ data })

//   return {
//     conversation: data,
//     isConversationLoading: !error && !data,
//     isConversationError: error,
//   }
// }
